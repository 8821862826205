<template>
  <div class="notFoundParent">
    <h1>404 {{ $t("notFound.pageNotFound") }}</h1>
    <span>
      {{ $t("notFound.toNavigateToHomePage") }}
      <router-link
        class="searchMenuButton"
        :to="$i18nRoute({ name: 'Overview' })"
      >
        {{ $t("notFound.clickHere") }}
      </router-link>
    </span>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.notFoundParent {
  height: 100%;
  overflow: auto;
  margin: 20px;
}
</style>